/* eslint-disable max-len */
import { createAction } from 'redux-actions';

import {
	GET_ATTENDANCE_HISTORY_CALL,
	GET_ATTENDANCE_HISTORY_DONE,
	GET_ATTENDANCE_HISTORY_FAIL,
} from '@src/store/types';

export const getAttendanceHistoryCall = createAction(GET_ATTENDANCE_HISTORY_CALL);
export const getAttendanceHistoryDone = createAction(GET_ATTENDANCE_HISTORY_DONE);
export const getAttendanceHistoryFail = createAction(GET_ATTENDANCE_HISTORY_FAIL);
