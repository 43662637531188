import React, { useEffect } from 'react';
import styled from 'styled-components';
import { useRouter } from 'next/router';
import { Menu } from 'antd';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';

import useMediaQuery from '@src/hooks/useMediaQuery';

import useTranslation from '@src/hooks/useTranslation';
import {
	// HrHomeIcon,
	SlidePersonalAreaTimeIcon,
	SlideFilePlusIcon,
	SlideInboxIcon,
	SlideApprovalIcon,
	SideMenuRightArrowIcon,
	SwitchHRLogoIconWhite,
	SwitchCourseLogoIconGreen,
} from '@src/components/common/icon';
import { getApprovalCenterCall } from '@src/store/actions/hrCenter';
import { getThemeSelector } from '@src/store/selectors';
import { RowWrapper } from '../hr/punchIn/styles';

// const HrHomeContent = styled.div.attrs({ className: 'hr-home-content' })`
// 	color: #676767;
// 	font-size: 14px;
// 	padding: 12px 15px;
// 	background: #FFF;
// 	cursor: pointer;

// 	@media
// 		${props => props.theme.device.mobile},
// 		${props => props.theme.device.smTablet}
// 		{
// 			padding: 12px;
// 		}
// `;

// const HrHomeWrapper = styled.div`
// 	display: flex;
// 	flex-direction: row;
// 	justify-content: center;
// 	align-items: center;
// 	width: 100%;
// 	padding: 8px 12px;
// 	border-radius: 8px;
// 	border: 1px solid #DDD;

// 	:hover {
// 		border: 1px solid #222;
// 	}

// 	@media
// 		${props => props.theme.device.mobile},
// 		${props => props.theme.device.smTablet}
// 		{
// 			padding: 6px;
// 		}
// `;

// const HrHomeText = styled.div`
// 	margin: 2px 0 0 8px;

// 	@media
// 		${props => props.theme.device.mobile},
// 		${props => props.theme.device.smTablet}
// 		{
// 			display: none;
// 		}
// `;

const AreaContent = styled.div`
	font-size: 14px;
	color: #676767;
	display: flex;
	flex-direction: row;
	align-items: center;
	white-space: nowrap;
	padding: 9px 0px 4px 16px;
	background: #FFF;
	font-weight: 400;
	height: 36px;
`;

const Divider = styled.div.attrs({ className: 'divider' })`
	width: calc(100% - 32px);
	height: 1px;
	background: #E9E9E9;
	margin: 0 16px;
`;

const StyledMenu = styled(Menu)`
	margin: 0px !important;
	width: calc(100% - 8px) !important;
	border-radius: 8px !important;
	&.ant-menu {
		height: unset !important;
	}
	.ant-menu-item-selected {
		color: #3091fd !important;
		&:hover {
			background: #D1E7F0 !important;
			color: #3091fd !important;
		}
		.fill-hover {
			path {
				fill:#3091FD;
			}
		}
		.stroke-hover {
			path {
				stroke:#3091FD;
			}
		}
	}
`;

const StyledMenuItem = styled(Menu.Item)`
	padding-left: 15px !important;
	margin-top: unset !important;
	margin-bottom: 4px !important;
	height: 40px !important;
	color: #333;
	font-size: 14px !important;
	font-weight: 400 !important;
	border-radius: 8px !important;
	:hover {
		background: #F5F5F7 !important;
		color: #333 !important;
	}

	::after{
		border-right: unset !important;
	}
	
	.ant-menu-title-content {
		display: flex;
		align-items: center;
	}
	@media ${props => props.theme.device.smTablet} {
		svg {
			overflow: unset !important;
		}
	}
	.ant-menu-inline-collapsed {
		svg {
			overflow: unset !important;
		}
	}
	@media ${props => props.theme.device.mobile} {
		font-size: 16px !important;
	}
`;

const StyledMenuSubMenu = styled(Menu.SubMenu)`
	position: relative;
	margin-bottom: 4px !important;
	.arrow-icon {
		transform: rotate(0deg);
	}
	.ant-menu {
		background-color: #FFF !important;
	}
	.ant-menu-submenu-title {
		border-radius: 8px !important;
		padding-left: 15px !important;
		margin-top: unset !important;
		margin-bottom: unset !important;
		height: 40px !important;
		padding-right: 8px !important;
		:hover {
			background: #F5F5F7 !important;
			color: #333 !important;
		}
	}
	&.ant-menu-submenu-open {
		.arrow-icon {
			transform: rotate(90deg);
		}
	}
	&.ant-menu-submenu-selected {
		& .ant-menu-submenu-title {
			color: #333 !important;
		}
	}
	@media ${props => props.theme.device.smTablet} {
		svg {
			overflow: unset !important;
		}
	}
	.ant-menu-inline-collapsed {
		svg {
			overflow: unset !important;
		}
	}
	@media ${props => props.theme.device.mobile} {
		font-size: 16px !important;
	}
`;

const SubMenuContent = styled.div`
	display: flex;
	align-items: center;
`;

const SubMenuItem = styled(Menu.Item)`
	padding-left: 42px !important;
	background-color: #FFF !important;
	margin-top: unset !important;
	margin-bottom: 4px !important;
	height: 40px !important;
	border-radius: 8px !important;
	:hover {
		background: #F5F5F7 !important;
		color: #333 !important;
	}
	::after{
		border-right: unset !important;
	}
	&.ant-menu-item-selected {
		background: #E7F4F9 !important;
		color: #3091FD !important;
	}
	@media ${props => props.theme.device.mobile} {
		font-size: 16px !important;
	}
`;

const StyledPersonalAreaTimeIcon = styled(SlidePersonalAreaTimeIcon)`
	margin-right: 8px;
`;

const StyledFilePlusIcon = styled(SlideFilePlusIcon)`
	margin-right: 8px;
	path {
		fill:#222;
	}
`;

const StyledInboxIcon = styled(SlideInboxIcon)`
	margin-right: 8px;
	path {
		stroke:#222;
	}
`;

const StyledApprovalIcon = styled(SlideApprovalIcon)`
	margin-right: 8px;
	path {
		stroke:#222;
	}
`;

const SystemOptionWrapper = styled(RowWrapper)`
	padding: 8px 16px;
	margin-bottom: 4px;
	gap: 8px;
	justify-content: flex-start;
	cursor: pointer;
	:hover {
		border-radius: 8px;
		background: #F5F5F7;
	}
`;

const SystemText = styled.div`
	cursor: pointer;
`;

const SwitchWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const HrMenu = ({
	className,
	collapsed,
	isPWA,
}: {
	className?: string;
	collapsed: boolean;
	isPWA?: boolean;
}): JSX.Element => {
	const { t } = useTranslation();
	const router = useRouter();
	const dispatch = useDispatch();
	const detail = useSelector((state: RootStateOrAny) => state.hrCenter.approvalCenter);
	const { themes } = useSelector(getThemeSelector) as any;

	useEffect(() => {
		dispatch(getApprovalCenterCall());
	}, []);
	const { pathname } = router;
	const { mediaMatch: { isMobile, isSmTablet } } = useMediaQuery();
	const openKeys = [];
	if ([
		'/hr/application',
		'/hr/overtime',
		'/hr/leave',
		'/hr/make-up-punch',
	].includes(pathname)
	) {
		openKeys.push('application');
	}

	let selectKey = '';
	if (['/hr/overtime-form'].includes(pathname)) {
		selectKey = '/hr/overtime';
	}
	if (['/hr/leave-form'].includes(pathname)) {
		selectKey = '/hr/leave';
	}
	if (['/hr/overtime/approver', '/hr/leave/approver'].includes(pathname)) {
		selectKey = '/hr/admin-center';
	}

	return (
		<div className={className}>
			{/* <HrHomeContent>
				<HrHomeWrapper onClick={() => router.push('/hr')}>
					<HrHomeIcon className="fill-hover" />
					<HrHomeText>{t('all:hr_system_homepage')}</HrHomeText>
				</HrHomeWrapper>
			</HrHomeContent> */}
			{
				isPWA && (themes?.can_switch_course_system || themes?.can_switch_hr_system) ? (
					<>
						<AreaContent>
							{t('all:switch_system')}
						</AreaContent>
						<SwitchWrapper>
							{
								themes?.can_switch_hr_system && (
									<SystemOptionWrapper onClick={() => router.push('/hr')}>
										<SwitchHRLogoIconWhite />
										<SystemText>
											{t('all:hr_system_name')}
										</SystemText>
									</SystemOptionWrapper>
								)
							}
							{
								themes?.can_switch_course_system && (
									<SystemOptionWrapper onClick={() => router.push('/')}>
										<SwitchCourseLogoIconGreen />
										<SystemText>
											{t('all:course_system_name')}
										</SystemText>
									</SystemOptionWrapper>
								)
							}
						</SwitchWrapper>
					</>
				) : null
			}
			<Divider />
			<AreaContent>
				{t('all:personal_area')}
			</AreaContent>
			<StyledMenu
				mode="inline"
				selectedKeys={[pathname, selectKey]}
				defaultOpenKeys={openKeys}
				style={{ height: '100%', borderRight: 0 }}
				inlineCollapsed={false}
				inlineIndent={54}
			>
				<StyledMenuItem key="/hr/attendance-history" onClick={() => router.push('/hr/attendance-history')}>
					<StyledPersonalAreaTimeIcon className="stroke-hover" />
					{t('all:attendance_record')}
				</StyledMenuItem>
				<StyledMenuItem key="/hr/leave/type" onClick={() => router.push('/hr/leave/type')}>
					<StyledFilePlusIcon className="fill-hover" />
					{t('all:leave_of_absence')}
				</StyledMenuItem>
				<StyledMenuSubMenu
					key="application"
					title={(
						<SubMenuContent>
							<StyledInboxIcon className="stroke-hover" />
							<span>{t('all:form_application')}</span>
						</SubMenuContent>
					)}
					expandIcon={<SideMenuRightArrowIcon className="arrow-icon" />}
				>
					<SubMenuItem key="/hr/application" onClick={() => router.push('/hr/application')}>
						<div>{t('all:form_application_record')}</div>
					</SubMenuItem>
					<SubMenuItem key="/hr/overtime" onClick={() => router.push('/hr/overtime')}>
						{t('all:overtime_form')}
					</SubMenuItem>
					<SubMenuItem key="/hr/leave" onClick={() => router.push('/hr/leave')}>
						{t('all:leave_application_form')}
					</SubMenuItem>
					<SubMenuItem key="/hr/make-up-punch" onClick={() => router.push('/hr/make-up-punch')}>
						{t('all:missed_clock_in_application_form')}
					</SubMenuItem>
				</StyledMenuSubMenu>
			</StyledMenu>
			{
				detail?.show_approval_center && (
					<>
						<Divider />
						<AreaContent>
							{(isMobile || isSmTablet) && !isPWA ? (
								t('all:admin_area_mobile')
							) : (
								t('all:admin_area')
							)}
						</AreaContent>
						<StyledMenu
							mode="inline"
							selectedKeys={[pathname, selectKey]}
							style={{ height: '100%', borderRight: 0 }}
							inlineCollapsed={collapsed}
							inlineIndent={54}
						>
							<StyledMenuItem key="/hr/admin-center" onClick={() => router.push('/hr/admin-center')}>
								<StyledApprovalIcon className="stroke-hover" />
								{t('all:approval_center')}
							</StyledMenuItem>
						</StyledMenu>
					</>
				)
			}
		</div>
	);
};

export default HrMenu;
