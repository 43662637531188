import styled from 'styled-components';
import { Form } from 'formik';
import EmptyInfo from '@src/components/common/emptyInfo';
import { ListChevronRight as ChevronRight } from '@src/components/common/icon';
import {	StyledTextWrap } from '@src/components/common/styles/table';
import { NotifyDot } from '@src/components/pwa/common';
import Button from '@src/components/common/button';
import Dropdown from '@src/components/common/subMenuDropdown';

export const ApplicationUserInfo = styled.div`
	display: flex;
	padding: 9px 12px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 4px;
	align-self: stretch;
	border-radius: 4px;
	border: 1px solid #DDD;
	color: #676767;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
`;

export const ApprovalRecordWrapper = styled.div`
	display: flex;
	padding: 8px 12px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 4px;
	align-self: stretch;
	color: #333;
	font-size: 14px;
	font-style: normal;
	font-weight: 400;
	line-height: 22px;
	.name {
		min-width: 80px;
		flex: 1;
		overflow-wrap: break-word;
	}
	.status {
		min-width: 90px;
		flex: 1;
	}
	.date {
		font-weight: 300;
		text-align: right;
		min-width: 107px;
	}
	.info {
		font-weight: 300;
	}
`;

export const ApprovalRecordItem = styled.div`
	display: flex;
	padding-bottom: 8px;
	flex-direction: column;
	justify-content: center;
	align-items: flex-start;
	gap: 4px;
	align-self: stretch;
	border-bottom: 1px solid #DDD;
	.row {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-direction: row;
		width: 100%;
	}
`;

export const ApprovalButtonWrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 12px;
`;

export const StyledEmptyInfo = styled(EmptyInfo)`
	margin-top: calc(50vh - 200px);
	margin-bottom: 20px;
`;

export const StatusWrap = styled(StyledTextWrap)`
	display: flex;
	align-content: center;
	justify-content: center;
	position: relative;

`;

export const StyledNotifyDot = styled(NotifyDot)`
	position: absolute;
	right: 30px;
`;

export const StyledChevronRight = styled(ChevronRight)`
	margin-left: 4px;
`;

export const StyledForm = styled(Form)`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`;

export const FormContent = styled.div`
	padding: 20px 32px;
	display: flex;
	justify-content: flex-start;
	flex-direction: column;
	min-height: 100vh;
	gap: 20px;
	margin-bottom: 100px;
	background-color: #F5F5F7;
	width: 100%;
	@media ${props => props.theme.device.mobile} {
		margin-top: 48px;
	}
	@media 
		${props => props.theme.device.smTablet},
		${props => props.theme.device.tablet}
		{
			max-width: 474px;
		}
	@media
		${props => props.theme.device.desktop},
		${props => props.theme.device.lgDesktop}
		{
			max-width: 610px;
		}
	
`;

export const SubmitButton = styled(Button)`
	width: 100%;
	height: 40px;
	padding: 8px;
	:first-child {
		margin-right: 18px;
	}
`;

export const ApprovalActionButton = styled(Button)`
	width: 100%;
	height: 40px;
	padding: 8px;
`;

export const InfoWrapper = styled.div`
	display: flex;
	flex-direction: column;
	color: #222;
	font-size: 14px;
	font-weight: 400;
	line-height: 22px;
	border: 1px solid #E9E9E9;
	padding: 8px 12px;
	gap: 4px;
	& :first-child {
		border-top-left-radius: 4px;
		border-top-right-radius: 4px;
		border-bottom: 0px;
	}
	& :last-child {
		border-bottom-left-radius: 4px;
		border-bottom-right-radius: 4px;
	}
	& :only-child {
		border: 1px solid #E9E9E9;
	}
	
	.subText {
		color: #676767;
		font-weight: 300;
	}
	.endText {
		text-align: right;
	}
`;

export const NoticeWrapper = styled.div`
	display: flex;
	flex-direction: row;
	color: #676767;
	font-size: 12px;
	font-style: normal;
	font-weight: 300;
	line-height: 18px;
	white-space: pre-wrap;
`;

export const RowWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
`;

export const GreyText = styled.div`
	color: #333;
	font-size: 14px;
	font-weight: 300;
`;

export const GreyBold = styled.div`
	color: #676767;
	font-size: 12px;
	font-weight: 500;
	margin-bottom: 8px;
`;

export const StyledDropdown = styled(Dropdown)`
	.dropdown-selected {
		width: 100%;
	}
	.text-content {
		width: 100%;
	}
`;
