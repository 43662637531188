import React from 'react';
import styled, { keyframes, createGlobalStyle } from 'styled-components';

import { ArrowLeftIcon, CloseIcon } from '@src/components/common/icon';

const GlobalStyle = createGlobalStyle`
	body {
		position: fixed;
		overflow: hidden;
	}
`;

const fadeInAndEnlarge = keyframes`
  from {
    opacity: 0;
		transform: scale(0.9);
  }
  to {
    opacity: 1;
		transform: scale(1);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const MODAL_HEADER_HEIGHT = 68;
const MODAL_FOOTER_HEIGHT = 67;
const MODAL_FOOTER_HEIGHT_MOBILE = 64;

const ModalWrapper = styled.div.attrs({ className: 'modal-wrapper' })`
	position: fixed;
	right: 0;
	top: 0;
	bottom: 0;
	left: 0;
	display: ${props => (props.isActive ? 'flex' : 'none')};
	width: 100vw;
	height: 100vh;
	z-index: 9999;
	justify-content: center;
	align-content: center;
`;

const ModalBackground = styled.div.attrs({ className: 'modal-background' })`
	position: fixed;
	right: -50%;
	top: -50%;
	bottom: -50%;
	left: -50%;
	background-color: rgba(0, 0, 0, 0.5);
	animation: ${fadeIn} 225ms cubic-bezier(0, 0, 0.2, 1) 0ms forwards;

	@media ${props => props.theme.device.mobile} {
		display: none;
	}
`;

const ModalContent = styled.div.attrs({ className: 'modal-content' })`
	position: relative;
	width: ${props => (props.modalWidth?.desktop ? props.modalWidth.desktop : 912)}px;
	border-radius: 8px;
	margin: auto;
	background: #fff;
	animation: ${fadeInAndEnlarge} 225ms cubic-bezier(0, 0, 0.2, 1) 0ms forwards;

	@media ${props => props.theme.device.tablet} {
		width: ${props => (props.modalWidth?.tablet ? props.modalWidth.tablet : 728)}px;
	}

	@media ${props => props.theme.device.smTablet} {
		width: ${props => (props.modalWidth?.smTablet ? props.modalWidth.smTablet : 496)}px;
	}

	@media ${props => props.theme.device.mobile} {
		position: fixed;
		width: 100vw;
		height: 100%;
		border-radius: unset;
		margin: 0;
	}
`;

const ModalHeader = styled.div`
	position: relative;
	padding: 20px 24px;
	border-bottom: 1px solid #F0F0F0;
	font-size: 18px;
	font-weight: 500;
	color: #101010;
	text-align: center;
`;

const ArrowLeftIconWrapper = styled(ArrowLeftIcon)`
	display: none;
	position: absolute;
	top: 22px;
	left: 24px;
	cursor: pointer;

	@media ${props => props.theme.device.mobile} {
		display: block;
	}
`;

const CloseIconWrapper = styled(CloseIcon)`
	position: absolute;
	top: 22px;
	right: 24px;
	cursor: pointer;

	@media ${props => props.theme.device.mobile} {
		display: none;
	}
`;

const ModalBody = styled.div.attrs({ className: 'modal-body' })`
	// Need to minus margin of modal and height of modal header and footer.
	max-height: ${props => (props.hasFooter ?
		`calc(100vh - ${MODAL_HEADER_HEIGHT}px - ${MODAL_FOOTER_HEIGHT}px)` :
		`calc(100vh - ${MODAL_HEADER_HEIGHT}px)`)};
	padding: 32px 24px;
	overflow-y: auto;

	@media ${props => props.theme.device.mobile} {
		height: ${props => (props.hasFooter ?
		`calc(100vh - ${MODAL_HEADER_HEIGHT}px - ${MODAL_FOOTER_HEIGHT_MOBILE}px)` :
		`calc(100vh - ${MODAL_HEADER_HEIGHT}px)`)};
		max-height: unset;
		padding: 24px 16px;
		overflow-x: hidden;
	}
`;

const ModalFooter = styled.div.attrs({ className: 'modal-footer' })`
	height: ${MODAL_FOOTER_HEIGHT}px;
	display: flex;
	align-items: center;
	justify-content: flex-end;
	padding: 12px 24px;
	border-top: 1px solid #F0F0F0;
	background: #fff;
	@media ${props => props.theme.device.mobile} {
		height: ${MODAL_FOOTER_HEIGHT_MOBILE}px;
		position: fixed;
		bottom: 0;
		width: 100%;
	}
`;

interface IModalWidth {
	desktop?: number;
	tablet?: number;
	smTablet?: number;
}

interface IModal {
	modalWidth?: IModalWidth;
	className?: string;
	isActive: boolean;
	noHeader?: boolean;
	headerText?: string;
	footer?: JSX.Element;
	handleClose?: () => void;
	children: JSX.Element | JSX.Element[];
	disableScroll?: boolean;
}

const Modal = ({
	modalWidth, className, isActive, noHeader,
	headerText, footer, handleClose, children,
	disableScroll = true,
}: IModal): JSX.Element => {
	const hasFooter = !!footer;
	if (!isActive) return null;
	return (
		<>
			{disableScroll && <GlobalStyle />}
			<div className={className}>
				<ModalWrapper isActive={isActive}>
					<ModalBackground onClick={handleClose} />
					<ModalContent modalWidth={modalWidth}>
						{!noHeader && (
							<ModalHeader>
								<ArrowLeftIconWrapper onClick={handleClose} />
								{headerText}
								<CloseIconWrapper onClick={handleClose} />
							</ModalHeader>
						)}
						<ModalBody id="modal-body" hasFooter={hasFooter}>{children}</ModalBody>
						{hasFooter && (<ModalFooter>{footer}</ModalFooter>)}
					</ModalContent>
				</ModalWrapper>
			</div>
		</>
	);
};

export default Modal;
